import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

const TeamMembers = () => {
  const teamMemberData = useStaticQuery(graphql`
  {
    allContentfulTeamMembers {
      nodes {
        name
        workTitle
        description
        teamMemberImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`);

  const teamMembers = teamMemberData.allContentfulTeamMembers.nodes;
  const sortTeamMembers = teamMembers;

  return (
    <>
      <section className="content-block flex flex-wrap relative w-full md:py-75 bg-cover bg-fixed bg-default-pattern text-secondary after:content after:w-full after:h-full after:absolute after:left-0 after:top-0 after:z-0 after:bg-overlay after:mix-blend-lighten">
        <div className="container xl:px-[3.5rem]  mx-auto pt-7rem px-4 relative z-2" data-wow-offset="50" data-wow-delay=".5s">
          <h2 className="w-full block mb-md font-bold text-[2.5rem] md:text-[3.5rem] leading-normal md:leadint-1.3 ">The Team</h2>
          <h5 className="mb-md">
            Behind every successful project are these amazing personalities, ready to deliver.
          </h5>
        </div>
      </section>

      <section className="content-block flex flex-wrap relative w-full py-xl md:py-75 font-futura">
        <div className="container xl:px-[3rem]  mx-auto">
          <div className="flex flex-wrap">
            {sortTeamMembers.map(({
              name,
              description,
              workTitle,
              teamMemberImage,
            }) => (
              <div key={name} className="object-fill flex flex-col w-full lg:w-3/12 sm:w-6/12 md:w-6/12 px-3">
                <div className="flex-1 w-full flex flex-wrap mb-6 wow" data-wow-offset="50" data-wow-delay=".5s">
                  <figure className="group w-full block mb-0 relative bg-primary">
                    <GatsbyImage className="group-hover:opacity-0.3" image={teamMemberImage.gatsbyImageData} alt="team" />
                    <figcaption className="group w-full absolute h-44px left-0 top-50% mt-22 text-center text-secondary text-opacity-0 group-hover:text-opacity-1">
                      <h6 className="mb-0 font-bold">{name}</h6>
                      <small className="uppercase">{workTitle}</small>
                      <p className="text-md flex w-80% m-auto pt-12px justify-center items-center">
                        {description}
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </>
  );
};

export default TeamMembers;
